@charset "UTF-8";
/**
 * mixins
 */
/* ▼モバイルファースト (全環境に共通のデザイン) */
/* ▼モバイルのみ付け足すデザイン */
@media all and (max-width: 767px) {
  .l-gnav {
    position: fixed;
    top: 0;
    right: -92vw;
    bottom: 0;
    z-index: 1001;
    width: 92vw;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    background: #fff !important;
  }
  .l-gnav .search-wrap {
    padding: 60px 15px 15px;
  }
  .l-gnav .search-wrap .btn-close {
    position: absolute;
    top: 8px;
    right: 8px;
    display: block;
    height: 48px;
    width: 52px;
    padding: 10px;
    color: #2d720a;
    font-weight: bold;
    font-style: italic;
    font-family: "Ubuntu", "游ゴシック体", YuGothic, "Yu Gothic Medium", "Yu Gothic UI", sans-serif;
    text-align: center;
    font-size: 0.71429rem;
    line-height: 1.4;
  }
  .l-gnav .search-wrap .btn-close img {
    display: block;
    width: 15px;
    margin: auto;
  }
  .l-gnav .nav-wrap {
    overflow: hidden;
    display: flex;
    width: 276vw;
    transform: translateX(0);
    height: 580px;
    box-shadow: 0 0 0 2px white inset;
    transition: .4s;
  }
  .l-gnav .nav-wrap .li-gnav {
    border-top: 1px solid #f4f4f4;
  }
  .l-gnav .nav-wrap .li-gnav a {
    position: relative;
    display: flex;
    align-items: center;
    height: 75px;
    padding: 10px 25px;
    font-style: italic;
    font-size: 1.14286rem;
    font-weight: bold;
  }
  .l-gnav .nav-wrap .li-gnav a:after {
    position: absolute;
    display: block;
    content: "";
  }
  .l-gnav .nav-wrap .li-gnav a:after {
    position: absolute;
    right: 25px;
    top: 50%;
    transform: translateY(-50%);
    width: 8px;
    height: 13px;
    background: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2216px%22%20height%3D%2226px%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20fill%3D%22rgb(45%2C%20114%2C%2010)%22%20d%3D%22M0.019%2C0.216%20L0.019%2C5.496%20L9.034%2C12.846%20L0.019%2C20.194%20L0.019%2C25.474%20L15.511%2C12.846%20L0.019%2C0.216%20Z%22%2F%3E%3C%2Fsvg%3E") no-repeat center center/contain;
  }
  .l-gnav .nav-wrap .li-gnav a.circle-r:after {
    right: 20px;
    width: 21px;
    height: 21px;
    border: 1px solid #abd298;
    border-radius: 50%;
    background: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212px%22%20height%3D%2212px%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20fill%3D%22rgb(45%2C%20114%2C%2010)%22%20d%3D%22M11.991%2C6.001%20L-0.007%2C11.993%20L-0.007%2C0.009%20L11.991%2C6.001%20Z%22%2F%3E%3C%2Fsvg%3E") no-repeat center 48%/6px 6px;
  }
  .l-gnav .nav-wrap .li-gnav a.icon-window:after {
    right: 20px;
    width: 21px;
    height: 21px;
    border: 1px solid #abd298;
    border-radius: 50%;
    background: url(../img/common/icon_window_gr.png) no-repeat center center/9px 9px;
  }
  .l-gnav .nav-wrap .li-gnav a .icon {
    width: 40px;
  }
  .l-gnav .nav-wrap .li-gnav a .icon img {
    max-width: 29px;
    max-height: 28px;
  }
  .l-gnav .nav-wrap .li-gnav a .text {
    font-size: 1.14286rem;
    color: #2d720a;
    font-weight: bold;
    font-style: italic;
  }
  .l-gnav .nav-wrap .li-gnav li {
    border-bottom: 1px solid #f4f4f4;
  }
  .l-gnav .nav-wrap .li-gnav .nav-home img {
    width: 82px;
    margin-right: 5px;
  }
  .l-gnav .nav-wrap .link-menuTop {
    margin-left: 15px;
    font-weight: bold;
    font-style: italic;
    vertical-align: middle;
  }
  .l-gnav .nav-wrap .link-menuTop:before {
    margin-top: -3px;
  }
  .l-gnav .nav-wrap .link-menuTop img {
    max-height: 16px;
    margin-right: 3px;
    margin-top: -3px;
    vertical-align: middle;
  }
  .l-gnav .nav-wrap .box-level01 {
    width: 92vw;
  }
  .l-gnav .nav-wrap .box-level01 .link-contact {
    font-size: 1.14286rem;
  }
  .l-gnav .nav-wrap .box-level02 {
    width: 92vw;
  }
  .l-gnav .nav-wrap .box-level02 .directory-menu {
    display: none;
  }
  .l-gnav .nav-wrap .box-level02 .directory-menu:first-child {
    display: block;
  }
  .l-gnav .nav-wrap .box-level02 .link-menuTop {
    margin-left: 15px;
    font-weight: bold;
    font-style: italic;
  }
  .l-gnav .nav-wrap .box-level02 .link-menuTop img {
    width: 45px;
    margin-right: 3px;
    vertical-align: middle;
  }
  .l-gnav .nav-wrap .box-level02 .ttl-directory {
    margin-top: 30px;
    margin-bottom: 25px;
  }
  .l-gnav .nav-wrap .box-level02 .ttl-directory .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    width: 72px;
    height: 72px;
    border-radius: 50%;
    background: #fffde4;
  }
  .l-gnav .nav-wrap .box-level02 .ttl-directory .icon img {
    max-width: 37px;
    max-height: 37px;
  }
  .l-gnav .nav-wrap .box-level02 .ttl-directory .text {
    display: block;
    font-size: 1.57143rem;
    color: #2d720a;
    font-weight: bold;
    font-style: italic;
  }
  .l-gnav .nav-wrap .box-level02 .li-gnav a {
    height: 57px;
  }
  .l-gnav .nav-wrap .box-level03 {
    width: 92vw;
  }
  .l-gnav .nav-wrap .box-level03 .ttl-directory {
    margin: 50px 0;
    font-size: 1.57143rem;
    font-weight: bold;
    font-style: italic;
  }
  .l-gnav .nav-wrap .box-level03 .li-gnav a {
    height: 60px;
  }
  .l-gnav .lang-wrap {
    padding: 60px 15px 40px;
  }
  .l-gnav .lang-wrap .box-language {
    margin-bottom: 25px;
  }
  .l-gnav .searchMenu-wrap {
    padding: 15px 15px 30px;
    background: #f5fbf4;
  }
  .l-gnav .searchMenu-wrap .c-btn {
    padding: 20px 20px 20px 100px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
    font-size: 1.14286rem;
    font-weight: bold;
  }
  .l-gnav .searchMenu-wrap .c-btn .icon {
    position: absolute;
    top: 50%;
    left: 23px;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 62px;
    height: 62px;
    border-radius: 50%;
    background: #fffde4;
  }
  .l-gnav .searchMenu-wrap .c-btn .icon img {
    max-width: 34px;
    max-height: 34px;
  }
  .l-gnav .searchMenu-wrap li + li {
    margin-top: 15px;
  }
  .l-main.is-localnavFix {
    padding-top: 56px;
  }
  .l-main.is-localnavFix .ui-accordionWrap {
    position: fixed;
    top: 63px;
    left: 0;
    right: 0;
    z-index: 990;
  }
  .l-main .box-footerAd {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 75px;
    background: #fff;
    text-align: center;
  }
  .l-main .box-footerAd img {
    max-width: 100%;
    max-height: 75px;
  }
  .p__top {
    padding-bottom: 75px;
  }
}

/* ▼タブレット用デザインとして付け足すデザイン */
@media all and (min-width: 768px) {
  .l-gnav.is-showsp {
    display: none;
  }
  .l-outline {
    padding-top: 129px;
    background-position: right top;
  }
  .l-outline .detail .l-footer .txt-copy {
    padding-bottom: 30px;
  }
  .l-container.box-headAd {
    display: none;
  }
  .l-container .ui-slider.skin02, .l-container .ui-slider.skin03 {
    display: flex;
    justify-content: space-between;
  }
  .l-container .ui-slider.skin02 .box-slide, .l-container .ui-slider.skin03 .box-slide {
    width: 29.1666%;
    padding: 0;
  }
  .l-container .ui-slider.skin02 .box-slide {
    width: 340px;
    padding: 15px 30px;
  }
  .l-container .ui-slider.skin02 .box-slide .c-card {
    width: 280px;
  }
  .l-container .ui-slider.skin02 .slick-prev {
    left: 32px;
  }
  .l-container .ui-slider.skin02 .slick-next {
    right: 32px;
  }
  .l-container .ui-slider.skin03 {
    margin-left: 0;
    margin-right: 0;
  }
  .l-container .ui-slider.skin03 .c-card {
    width: auto;
  }
  .l-container .ui-tab .li-tabmenu li:hover {
    cursor: pointer;
  }
  .l-container .ui-spAccordion {
    border: none;
  }
  .l-container .ui-spAccordion .ui-spacTitle {
    display: none;
  }
  .l-container .ui-spAccordion .ui-spacContent {
    display: block;
    background: none;
    box-shadow: none;
  }
  .l-container .ui-spAccordion .ui-spacContent .li-pageMenu {
    display: flex;
    justify-content: space-between;
  }
  .l-container .ui-spAccordion .ui-spacContent .li-pageMenu li {
    border: none;
  }
  .l-container .ui-spAccordion .ui-spacContent .li-pageMenu a {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 16px 10px;
  }
  .l-container .ui-spAccordion .ui-spacContent .li-pageMenu a:after {
    content: none;
  }
  .l-container .ui-spAccordion .ui-spacContent .li-pageMenu a span {
    padding-left: 0px;
    font-weight: bold;
    font-size: 0.92857rem;
    line-height: 1.55;
  }
  .l-container .ui-spAccordion .ui-spacContent .li-pageMenu .ttl a {
    position: relative;
  }
  .l-container .ui-spAccordion .ui-spacContent .li-pageMenu .ttl a:after {
    position: absolute;
    display: block;
    content: "";
  }
  .l-container .ui-spAccordion .ui-spacContent .li-pageMenu .ttl a:after {
    left: 0;
    right: 0;
    bottom: 0;
    height: 4px;
    background: #2d720a;
  }
  .l-container .ui-spAccordion .ui-spacContent .li-pageMenu li {
    border-top: 0;
  }
  .l-container .ui-spAccordion .ui-spacContent .li-pageMenu li.is-select a:before {
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    content: "";
    position: absolute;
    height: 4px;
    background: #2d720a;
  }
  .l-container .ui-accordionWrap {
    background: #fbfbfb;
  }
  .l-container .ui-acContent .inner {
    padding: 30px 23px;
  }
  .l-container .c-btn {
    display: inline-block;
  }
  .l-container .c-btn.is-round {
    width: auto;
    height: 30px;
    padding: 4px 10px;
  }
  .l-container .c-btn.is-color {
    padding: 3px 10px;
    border-radius: 2px 2px 16px 2px;
  }
  .l-container .c-btn.ar-b {
    padding-right: 20px;
  }
  .l-container .c-btn.ar-b:after {
    right: 8px;
    width: 11px;
  }
  .l-container .c-card.pc-horizontal {
    display: flex;
  }
  .l-container .c-card.pc-horizontal figure {
    width: 40%;
  }
  .l-container .c-card.pc-horizontal .text {
    display: flex;
    align-items: center;
  }
  .l-container .c-card.is-horizontal figure {
    width: 162px;
    height: auto;
  }
  .l-container .c-card.is-horizontal .text {
    flex: 1;
  }
  .l-container .c-searchWrap {
    display: inline-block;
  }
  .l-container .c-searchWrap .c-search {
    width: auto;
    height: 30px;
    padding: 4px 10px;
  }
  .l-container .c-columns {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .l-container .c-columns > [class*=col-] + [class*=col-] {
    margin-bottom: 0;
  }
  .l-container .c-columns.mb > [class*=col-] + [class*=col-] {
    margin-bottom: 30px;
  }
  .l-container .c-columns.auto {
    flex-wrap: nowrap;
  }
  .l-container .c-columns.auto > .c-col {
    flex: 1;
  }
  .l-container .c-columns.gutters {
    margin-left: -30px;
  }
  .l-container .c-columns.gutters > .c-col {
    margin-left: 30px;
  }
  .l-container .c-columns.gutters > .c-col-1 {
    width: calc(8.33333% - 30px);
    margin-left: 30px;
  }
  .l-container .c-columns.gutters > .offset-1 {
    margin-left: calc(8.33333% + 30px) !important;
  }
  .l-container .c-columns.gutters > .c-col-2 {
    width: calc(16.66667% - 30px);
    margin-left: 30px;
  }
  .l-container .c-columns.gutters > .offset-2 {
    margin-left: calc(16.66667% + 30px) !important;
  }
  .l-container .c-columns.gutters > .c-col-3 {
    width: calc(25% - 30px);
    margin-left: 30px;
  }
  .l-container .c-columns.gutters > .offset-3 {
    margin-left: calc(25% + 30px) !important;
  }
  .l-container .c-columns.gutters > .c-col-4 {
    width: calc(33.33333% - 30px);
    margin-left: 30px;
  }
  .l-container .c-columns.gutters > .offset-4 {
    margin-left: calc(33.33333% + 30px) !important;
  }
  .l-container .c-columns.gutters > .c-col-5 {
    width: calc(41.66667% - 30px);
    margin-left: 30px;
  }
  .l-container .c-columns.gutters > .offset-5 {
    margin-left: calc(41.66667% + 30px) !important;
  }
  .l-container .c-columns.gutters > .c-col-6 {
    width: calc(50% - 30px);
    margin-left: 30px;
  }
  .l-container .c-columns.gutters > .offset-6 {
    margin-left: calc(50% + 30px) !important;
  }
  .l-container .c-columns.gutters > .c-col-7 {
    width: calc(58.33333% - 30px);
    margin-left: 30px;
  }
  .l-container .c-columns.gutters > .offset-7 {
    margin-left: calc(58.33333% + 30px) !important;
  }
  .l-container .c-columns.gutters > .c-col-8 {
    width: calc(66.66667% - 30px);
    margin-left: 30px;
  }
  .l-container .c-columns.gutters > .offset-8 {
    margin-left: calc(66.66667% + 30px) !important;
  }
  .l-container .c-columns.gutters > .c-col-9 {
    width: calc(75% - 30px);
    margin-left: 30px;
  }
  .l-container .c-columns.gutters > .offset-9 {
    margin-left: calc(75% + 30px) !important;
  }
  .l-container .c-columns.gutters > .c-col-10 {
    width: calc(83.33333% - 30px);
    margin-left: 30px;
  }
  .l-container .c-columns.gutters > .offset-10 {
    margin-left: calc(83.33333% + 30px) !important;
  }
  .l-container .c-columns.gutters > .c-col-11 {
    width: calc(91.66667% - 30px);
    margin-left: 30px;
  }
  .l-container .c-columns.gutters > .offset-11 {
    margin-left: calc(91.66667% + 30px) !important;
  }
  .l-container .c-columns.gutters > .c-col-12 {
    width: calc(100% - 30px);
    margin-left: 30px;
  }
  .l-container .c-columns.gutters > .offset-12 {
    margin-left: calc(100% + 30px) !important;
  }
  .l-container .c-columns + .c-columns {
    margin-top: 40px;
  }
  .l-container .c-pcmt0 {
    margin-top: 0px !important;
  }
  .l-container .c-pcmr0 {
    margin-right: 0px !important;
  }
  .l-container .c-pcmb0 {
    margin-bottom: 0px !important;
  }
  .l-container .c-pcml0 {
    margin-left: 0px !important;
  }
  .l-container .c-pcpt0 {
    padding-top: 0px !important;
  }
  .l-container .c-pcpr0 {
    padding-right: 0px !important;
  }
  .l-container .c-pcpb0 {
    padding-bottom: 0px !important;
  }
  .l-container .c-pcpl0 {
    padding-left: 0px !important;
  }
  .l-container .c-pcmt5 {
    margin-top: 5px !important;
  }
  .l-container .c-pcmr5 {
    margin-right: 5px !important;
  }
  .l-container .c-pcmb5 {
    margin-bottom: 5px !important;
  }
  .l-container .c-pcml5 {
    margin-left: 5px !important;
  }
  .l-container .c-pcpt5 {
    padding-top: 5px !important;
  }
  .l-container .c-pcpr5 {
    padding-right: 5px !important;
  }
  .l-container .c-pcpb5 {
    padding-bottom: 5px !important;
  }
  .l-container .c-pcpl5 {
    padding-left: 5px !important;
  }
  .l-container .c-pcmt10 {
    margin-top: 10px !important;
  }
  .l-container .c-pcmr10 {
    margin-right: 10px !important;
  }
  .l-container .c-pcmb10 {
    margin-bottom: 10px !important;
  }
  .l-container .c-pcml10 {
    margin-left: 10px !important;
  }
  .l-container .c-pcpt10 {
    padding-top: 10px !important;
  }
  .l-container .c-pcpr10 {
    padding-right: 10px !important;
  }
  .l-container .c-pcpb10 {
    padding-bottom: 10px !important;
  }
  .l-container .c-pcpl10 {
    padding-left: 10px !important;
  }
  .l-container .c-pcmt15 {
    margin-top: 15px !important;
  }
  .l-container .c-pcmr15 {
    margin-right: 15px !important;
  }
  .l-container .c-pcmb15 {
    margin-bottom: 15px !important;
  }
  .l-container .c-pcml15 {
    margin-left: 15px !important;
  }
  .l-container .c-pcpt15 {
    padding-top: 15px !important;
  }
  .l-container .c-pcpr15 {
    padding-right: 15px !important;
  }
  .l-container .c-pcpb15 {
    padding-bottom: 15px !important;
  }
  .l-container .c-pcpl15 {
    padding-left: 15px !important;
  }
  .l-container .c-pcmt20 {
    margin-top: 20px !important;
  }
  .l-container .c-pcmr20 {
    margin-right: 20px !important;
  }
  .l-container .c-pcmb20 {
    margin-bottom: 20px !important;
  }
  .l-container .c-pcml20 {
    margin-left: 20px !important;
  }
  .l-container .c-pcpt20 {
    padding-top: 20px !important;
  }
  .l-container .c-pcpr20 {
    padding-right: 20px !important;
  }
  .l-container .c-pcpb20 {
    padding-bottom: 20px !important;
  }
  .l-container .c-pcpl20 {
    padding-left: 20px !important;
  }
  .l-container .c-pcmt25 {
    margin-top: 25px !important;
  }
  .l-container .c-pcmr25 {
    margin-right: 25px !important;
  }
  .l-container .c-pcmb25 {
    margin-bottom: 25px !important;
  }
  .l-container .c-pcml25 {
    margin-left: 25px !important;
  }
  .l-container .c-pcpt25 {
    padding-top: 25px !important;
  }
  .l-container .c-pcpr25 {
    padding-right: 25px !important;
  }
  .l-container .c-pcpb25 {
    padding-bottom: 25px !important;
  }
  .l-container .c-pcpl25 {
    padding-left: 25px !important;
  }
  .l-container .c-pcmt30 {
    margin-top: 30px !important;
  }
  .l-container .c-pcmr30 {
    margin-right: 30px !important;
  }
  .l-container .c-pcmb30 {
    margin-bottom: 30px !important;
  }
  .l-container .c-pcml30 {
    margin-left: 30px !important;
  }
  .l-container .c-pcpt30 {
    padding-top: 30px !important;
  }
  .l-container .c-pcpr30 {
    padding-right: 30px !important;
  }
  .l-container .c-pcpb30 {
    padding-bottom: 30px !important;
  }
  .l-container .c-pcpl30 {
    padding-left: 30px !important;
  }
  .l-container .c-pcmt35 {
    margin-top: 35px !important;
  }
  .l-container .c-pcmr35 {
    margin-right: 35px !important;
  }
  .l-container .c-pcmb35 {
    margin-bottom: 35px !important;
  }
  .l-container .c-pcml35 {
    margin-left: 35px !important;
  }
  .l-container .c-pcpt35 {
    padding-top: 35px !important;
  }
  .l-container .c-pcpr35 {
    padding-right: 35px !important;
  }
  .l-container .c-pcpb35 {
    padding-bottom: 35px !important;
  }
  .l-container .c-pcpl35 {
    padding-left: 35px !important;
  }
  .l-container .c-pcmt40 {
    margin-top: 40px !important;
  }
  .l-container .c-pcmr40 {
    margin-right: 40px !important;
  }
  .l-container .c-pcmb40 {
    margin-bottom: 40px !important;
  }
  .l-container .c-pcml40 {
    margin-left: 40px !important;
  }
  .l-container .c-pcpt40 {
    padding-top: 40px !important;
  }
  .l-container .c-pcpr40 {
    padding-right: 40px !important;
  }
  .l-container .c-pcpb40 {
    padding-bottom: 40px !important;
  }
  .l-container .c-pcpl40 {
    padding-left: 40px !important;
  }
  .l-container .c-pcmt45 {
    margin-top: 45px !important;
  }
  .l-container .c-pcmr45 {
    margin-right: 45px !important;
  }
  .l-container .c-pcmb45 {
    margin-bottom: 45px !important;
  }
  .l-container .c-pcml45 {
    margin-left: 45px !important;
  }
  .l-container .c-pcpt45 {
    padding-top: 45px !important;
  }
  .l-container .c-pcpr45 {
    padding-right: 45px !important;
  }
  .l-container .c-pcpb45 {
    padding-bottom: 45px !important;
  }
  .l-container .c-pcpl45 {
    padding-left: 45px !important;
  }
  .l-container .c-pcmt50 {
    margin-top: 50px !important;
  }
  .l-container .c-pcmr50 {
    margin-right: 50px !important;
  }
  .l-container .c-pcmb50 {
    margin-bottom: 50px !important;
  }
  .l-container .c-pcml50 {
    margin-left: 50px !important;
  }
  .l-container .c-pcpt50 {
    padding-top: 50px !important;
  }
  .l-container .c-pcpr50 {
    padding-right: 50px !important;
  }
  .l-container .c-pcpb50 {
    padding-bottom: 50px !important;
  }
  .l-container .c-pcpl50 {
    padding-left: 50px !important;
  }
  .l-container .c-pcw0 {
    width: 0%;
  }
  .l-container .c-pcw5 {
    width: 5%;
  }
  .l-container .c-pcw10 {
    width: 10%;
  }
  .l-container .c-pcw15 {
    width: 15%;
  }
  .l-container .c-pcw20 {
    width: 20%;
  }
  .l-container .c-pcw25 {
    width: 25%;
  }
  .l-container .c-pcw30 {
    width: 30%;
  }
  .l-container .c-pcw35 {
    width: 35%;
  }
  .l-container .c-pcw40 {
    width: 40%;
  }
  .l-container .c-pcw45 {
    width: 45%;
  }
  .l-container .c-pcw50 {
    width: 50%;
  }
  .l-container .c-pcw55 {
    width: 55%;
  }
  .l-container .c-pcw60 {
    width: 60%;
  }
  .l-container .c-pcw65 {
    width: 65%;
  }
  .l-container .c-pcw70 {
    width: 70%;
  }
  .l-container .c-pcw75 {
    width: 75%;
  }
  .l-container .c-pcw80 {
    width: 80%;
  }
  .l-container .c-pcw85 {
    width: 85%;
  }
  .l-container .c-pcw90 {
    width: 90%;
  }
  .l-container .c-pcw95 {
    width: 95%;
  }
  .l-container .c-pcw100 {
    width: 100%;
  }
  .l-container .is-showpc {
    display: block !important;
  }
  .l-container .is-showpc.inline {
    display: inline-block !important;
  }
  .l-container .is-showpc.flex {
    display: flex !important;
  }
  .l-container .is-showsp {
    display: none !important;
  }
  .l-header {
    top: 0;
  }
  .l-header.is-fix {
    background: none;
  }
  .l-header .logo-wrap .logo {
    width: 130px;
    padding-right: 5px;
  }
  .l-header .logo-wrap .logo_nexco {
    margin-left: 6px;
  }
  .l-header .logo-wrap .btn-anchor {
    display: none !important;
  }
  .l-header .logo-wrap .btn-anchor:before {
    display: inline-block;
    content: "";
    width: 11px;
    height: 11px;
    background: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2211px%22%20height%3D%2211px%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20fill%3D%22rgb(58%2C%20109%2C%2032)%22%20d%3D%22M11.000%2C5.500%20L10.031%2C4.531%20L6.187%2C8.367%20L6.187%2C-0.000%20L4.813%2C-0.000%20L4.813%2C8.367%20L0.976%2C4.524%20L-0.000%2C5.500%20L5.500%2C11.000%20L11.000%2C5.500%20Z%22%2F%3E%3C%2Fsvg%3E") no-repeat center/11px 11px;
  }
  .l-header .logo-wrap .btn-login {
    font-size: 1rem;
  }
  .l-header .logo-wrap .btn-login .is-fsizeSmaller {
    font-size: 0.71429rem;
  }
  .l-header .logo-wrap .btn-language {
    padding-right: 30px;
    color: #b1b1b1;
    font-size: 1rem;
  }
  .l-header .logo-wrap .btn-language .icon img {
    width: 20px;
  }
  .l-header .logo-wrap .c-search {
    font-size: 1rem;
  }
  .l-header .btn-wrap {
    align-items: center;
    margin-left: auto;
  }
  .l-header .btn-wrap a {
    margin-right: 3px;
  }
  .l-header .inner {
    height: auto;
  }
  .l-header .l-gnav {
    position: relative;
    padding: 0 10px;
    border-top: 1px solid #f4f4f4;
  }
  .l-header .l-gnav .li-pcNav {
    display: flex;
    justify-content: space-between;
    font-size: 0.85714rem;
    font-weight: bold;
  }
  .l-header .l-gnav .li-pcNav li.is-current a:after {
    background: #2d720a;
  }
  .l-header .l-gnav .li-pcNav a {
    position: relative;
    display: flex;
    align-items: center;
    padding: 10px 0;
    height: 100%;
    min-height: 58px;
  }
  .l-header .l-gnav .li-pcNav a:before {
    position: absolute;
    display: block;
    content: "";
  }
  .l-header .l-gnav .li-pcNav a:before {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 3px;
    background: transparent;
    transition: .4s;
  }
  .l-header .l-gnav .li-pcNav li a:hover:before,
  .l-header .l-gnav .li-pcNav li.is-select a:before,
  .l-header .l-gnav .li-pcNav li.is-open a:before {
    background: #2d720a;
  }
  .l-header .l-gnav .li-pcNav .icon {
    padding-right: 3px;
  }
  .l-header .l-gnav .li-pcNav .icon img {
    max-width: 20px;
    max-height: 20px;
    vertical-align: middle;
  }
  .l-header .l-megaWrap {
    position: absolute;
    top: 58px;
    left: 0;
    right: 0;
    z-index: 1001;
    overflow: hidden;
    border-top: 1px solid #d5e4ce;
    background: #fff;
    display: none;
    padding: 5px 0 20px;
    box-shadow: 0px 30px 20px -20px rgba(0, 0, 0, 0.3);
  }
  .l-header .l-megaWrap .li-secondary {
    padding: 0 25px;
  }
  .l-header .l-megaWrap .li-secondary > li {
    padding: 10px 0;
  }
  .l-header .l-megaWrap .li-secondary > li + li {
    border-top: 1px solid #cbdfc0;
  }
  .l-header .l-megaWrap .li-secondary > li a {
    display: inline-block;
    min-height: 0;
    padding: 5px;
    font-size: 1rem;
  }
  .l-header .l-megaWrap .li-secondary > li a:before {
    content: none;
  }
  .l-header .l-megaWrap .li-secondary > li a:after {
    position: static;
    display: inline-block;
    content: "";
    width: 5px;
    height: 8px;
    margin-left: 8px;
    background: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%225.5px%22%20height%3D%228.5px%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20stroke%3D%22rgb(46%2C%20120%2C%208)%22%20stroke-width%3D%221px%22%20stroke-linecap%3D%22butt%22%20stroke-linejoin%3D%22miter%22%20fill%3D%22none%22%20d%3D%22M1.387%2C0.491%20L4.508%2C4.000%20L1.387%2C7.509%20%22%2F%3E%3C%2Fsvg%3E") no-repeat center center/5px auto;
  }
  .l-header .l-megaWrap .li-secondary > li > a {
    font-weight: bold;
    font-size: 1.14286rem;
  }
  .l-header .l-megaWrap .li-secondary .is-top {
    display: flex;
    justify-content: space-between;
  }
  .l-header .l-megaWrap .li-secondary .is-top .btn-close {
    position: relative;
    width: 36px;
    height: 36px;
    margin-top: -5px;
    border-radius: 4px;
    border: none;
    outline: none;
    -webkit-appearance: none;
    background: #2d720a;
  }
  .l-header .l-megaWrap .li-secondary .is-top .btn-close:before, .l-header .l-megaWrap .li-secondary .is-top .btn-close:after {
    position: absolute;
    top: 17px;
    left: 9px;
    display: block;
    content: "";
    width: 18px;
    height: 2px;
    background: #fff;
  }
  .l-header .l-megaWrap .li-secondary .is-top .btn-close:before {
    transform: rotate(45deg);
  }
  .l-header .l-megaWrap .li-secondary .is-top .btn-close:after {
    transform: rotate(-45deg);
  }
  .l-header .l-megaWrap .li-tertiary {
    display: flex;
    flex-wrap: wrap;
    margin-top: 5px;
    padding-left: 16px;
  }
  .l-header .l-megaWrap .li-tertiary li {
    margin: 0 15px 0 0;
  }
  .l-header .l-megaWrap .li-tertiary a.c-icon-right:after {
    position: static;
    display: inline-block;
    margin-left: 5px;
  }
  .l-main .box-footerAd {
    margin-top: 65px;
  }
  .l-main .mv-wrap img {
    border-radius: 0 0 112px 0;
  }
  .l-main .search-wrap .c-columns {
    justify-content: space-between;
  }
  .l-main .search-wrap .c-columns > .c-roundbox {
    width: calc((100% - 40px) / 3);
  }
  .l-main .search-wrap .c-btn {
    display: block;
    max-width: 188px;
    margin-left: auto;
    margin-right: auto;
  }
  .l-main .infomation-wrap .li-infomation {
    display: flex;
    flex-wrap: wrap;
    margin: -30px 0 0 -30px;
  }
  .l-main .infomation-wrap .li-infomation li {
    width: calc(50% - 30px);
    margin: 30px 0 0 30px;
  }
  .l-main .various-wrap .li-bnr {
    flex-wrap: nowrap;
    margin: 0 0 0 -16px;
  }
  .l-main .various-wrap .li-bnr li {
    flex: 1;
    margin-left: 16px;
  }
  .l-main .various-wrap .li-bnr li + li {
    margin-top: 0;
  }
  .l-main .app-wrap .li-app {
    display: flex;
  }
  .l-main .app-wrap .li-app li {
    flex: 1;
    border: none;
  }
  .l-main .app-wrap .li-app li a {
    display: block;
    text-align: center;
  }
  .l-main .app-wrap .li-app li a:after {
    content: none;
  }
  .l-main .app-wrap .li-app li .icon {
    margin: auto;
  }
  .l-main .app-wrap .li-app li .text:after {
    display: inline-block;
    content: "";
    width: 21px;
    height: 21px;
    margin-top: -3px;
    margin-left: 5px;
    border: 1px solid #abd298;
    border-radius: 50%;
    background: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212px%22%20height%3D%2212px%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20fill%3D%22rgb(45%2C%20114%2C%2010)%22%20d%3D%22M11.991%2C6.001%20L-0.007%2C11.993%20L-0.007%2C0.009%20L11.991%2C6.001%20Z%22%2F%3E%3C%2Fsvg%3E") no-repeat center 48%/6px 6px;
    vertical-align: middle;
  }
  .l-main .pagetitle-wrap .bg-sapa .box-search .c-btn {
    display: block;
    max-width: 340px;
    margin-left: auto;
    margin-right: auto;
  }
  .l-main .pagetitle-wrap .c-roundbox {
    max-width: 832px;
    margin-left: auto;
    margin-right: auto;
    padding: 30px;
  }
  .l-main .pagetitle-wrap .c-roundbox .li-servicearea {
    max-width: 340px;
    margin-left: auto;
    margin-right: auto;
  }
  .l-main .pagetitle-wrap .c-roundbox .c-search {
    height: 48px;
    width: 356px;
    margin-left: 8px;
    margin-right: 40px;
  }
  .l-main .pagetitle-wrap .c-roundbox .label-radio input[type="radio"] + span {
    font-size: 1rem;
  }
  .l-main .pagetitle-wrap.detail .txt-road {
    margin-top: 35px;
    font-size: 1.14286rem;
  }
  .l-main .pagetitle-wrap.detail .txt-ic {
    padding-top: 12px;
    font-size: 2.57143rem;
  }
  .l-main .pagetitle-wrap.detail .txt-ic .label {
    padding: 2px 10px 2px 5px;
    margin-left: 8px;
    margin-top: -5px;
    font-size: 1.42857rem;
  }
  .l-main .pagetitle-wrap.detail .txt-rubi {
    font-size: 1rem;
  }
  .l-main .pagetitle-wrap.detail .btn-wrap {
    margin-top: 15px;
    padding-bottom: 40px;
  }
  .l-main .pagetitle-wrap.detail .btn-wrap .c-btn {
    display: block;
  }
  .l-main .recommned-wrap .l-wrapper {
    padding-left: 0;
    padding-right: 0;
  }
  .l-main .recommned-wrap .c-card {
    width: 280px;
    height: 100%;
  }
  .l-main .recommned-wrap .box-slide {
    height: 100%;
    padding: 15px 30px;
  }
  .l-main .recommned-wrap .slick-arrow.slick-prev {
    left: 32px;
  }
  .l-main .recommned-wrap .slick-arrow.slick-next {
    right: 32px;
  }
  .l-main .check-wrap .li-check {
    display: flex;
    flex-wrap: wrap;
    margin: -30px 0 0 -30px;
  }
  .l-main .check-wrap .li-check li {
    width: calc(50% - 30px);
    margin: 30px 0 0 30px;
  }
  .l-main .check-wrap .li-check li + li {
    margin-top: 30px;
  }
  .l-main .history-wrap .li-history figure {
    text-align: center;
  }
  .l-main .history-wrap .li-history li {
    width: calc(25% - 15px);
  }
  .l-main .fbnr-wrap .li-bnr {
    display: flex;
    flex-wrap: wrap;
    margin: -30px 0 0 -30px;
  }
  .l-main .fbnr-wrap .li-bnr li {
    width: calc(33.3333% - 30px);
    margin: 30px 0 0 30px;
  }
  .l-main .fbnr-wrap .li-bnr li + li {
    margin-top: 30px;
  }
  .l-main .fbnr-wrap .li-bnr li a {
    border-radius: 4px;
  }
  .l-main .fun-wrap .li-fun {
    display: flex;
    flex-wrap: wrap;
    margin: -30px 0 0 -30px;
  }
  .l-main .fun-wrap .li-fun li {
    width: calc(50% - 30px);
    margin: 30px 0 0 30px;
  }
  .l-main .fun-wrap .li-fun li + li {
    margin-top: 30px;
  }
  .l-main .ranking-wrap .c-card.pc-horizontal {
    padding-bottom: 0;
  }
  .l-main .ranking-wrap .c-card.pc-horizontal figure {
    width: 280px;
    height: 100%;
  }
  .l-main .ranking-wrap .c-card.pc-horizontal figure .is-imgfit {
    width: 100%;
    height: 100%;
  }
  .l-main .ranking-wrap .c-card.pc-horizontal .text {
    flex: 1;
    display: block;
  }
  .l-main .catch-wrap .ui-slider {
    width: auto !important;
  }
  .l-main .catch-wrap .ui-slider .box-slide {
    width: calc((99.9999% - 60px)/3);
  }
  .l-main .areasearch-wrap .c-roundbox {
    padding-bottom: 0;
  }
  .l-main .areasearch-wrap .c-searchWrap {
    display: block;
    max-width: 428px;
    margin: 32px;
  }
  .l-main .areasearch-wrap .c-searchWrap .c-search {
    width: 100%;
    height: 48px;
    padding: 4px 20px;
  }
  .l-main .areasearch-wrap .li-areasearch {
    display: flex;
    margin-top: 15px;
  }
  .l-main .areasearch-wrap .li-areasearch li {
    flex: 1;
    border-bottom: 0;
    border-right: 1px solid #f4f4f4;
  }
  .l-main .areasearch-wrap .li-areasearch li a {
    display: flex;
    height: 100%;
    align-items: center;
  }
  .l-main .areasearch-wrap .li-areasearch li:last-child {
    border: 0;
  }
  .l-main .areasearch-wrap .li-areasearch li:last-child a {
    border-radius: 0 0 56px 0;
  }
  .l-main .fixed-nav {
    top: 315px;
    left: auto;
    bottom: auto;
    padding: 0;
    background: none;
  }
  .l-main .fixed-nav .c-btn {
    width: 156px;
    height: 90px;
    padding: 20px 40px 10px 10px;
    border-radius: 10em 0 0 10em;
    border: none;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
  }
  .l-main .fixed-nav .c-btn:after {
    right: 12px;
    width: 30px;
    height: 30px;
    background: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2217px%22%20height%3D%2218px%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20fill%3D%22rgb(45%2C%20114%2C%2010)%22%20d%3D%22M17.000%2C16.109%20L15.215%2C17.996%20L11.193%2C13.741%20C10.037%2C14.592%208.640%2C15.100%207.128%2C15.100%20C3.195%2C15.100%20-0.004%2C11.715%20-0.004%2C7.553%20C-0.004%2C3.391%203.195%2C0.007%207.128%2C0.007%20C11.061%2C0.007%2014.262%2C3.391%2014.262%2C7.553%20C14.262%2C9.153%2013.782%2C10.632%2012.976%2C11.854%20L17.000%2C16.109%20ZM7.128%2C2.676%20C4.585%2C2.676%202.518%2C4.864%202.518%2C7.553%20C2.518%2C10.243%204.585%2C12.431%207.128%2C12.431%20C9.671%2C12.431%2011.740%2C10.243%2011.740%2C7.553%20C11.740%2C4.864%209.671%2C2.676%207.128%2C2.676%20Z%22%2F%3E%3C%2Fsvg%3E") no-repeat center center/13px auto;
  }
  .l-main .fixed-nav .c-btn .icon {
    display: block;
  }
  .l-main .fixed-nav .c-btn .text {
    display: block;
    font-size: 0.85714rem;
    line-height: 1.16;
  }
  .l-main .fixed-navContent {
    top: 160px;
    left: auto;
    right: -100%;
    bottom: auto;
    padding: 0;
    background: none;
  }
  .l-main .fixed-navContent .inner {
    position: relative;
    border-radius: 64px 0 0 64px;
    padding-bottom: 0;
  }
  .l-main .fixed-navContent .btn-close {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 60px;
    height: 60px;
    padding: 35px 0 0;
    border: none;
    font-size: 1rem;
    text-align: center;
  }
  .l-main .fixed-navContent .btn-close:after {
    top: 5px;
    right: auto;
    left: 50%;
    transform: translate(-50%, 0);
    width: 30px;
    height: 30px;
    background-size: 25px auto;
    border: none;
  }
  .l-main .fixed-navContent .btn-close:hover {
    opacity: .75;
    color: #2d720a;
  }
  .l-main .fixed-navContent .li-search {
    padding: 55px 30px 15px;
  }
  .l-main .fixed-navContent .li-search dt:nth-of-type(2) {
    width: 9%;
  }
  .l-main .fixed-navContent .li-search dd:nth-of-type(1) {
    width: 63.7%;
    padding-right: 25px;
  }
  .l-main .fixed-navContent .li-search dd:nth-of-type(2) {
    width: 27.3%;
    font-size: 1rem;
  }
  .l-main .fixed-navContent .li-search .c-searchWrap .c-search {
    width: 100%;
    height: 48px;
  }
  .l-main .fixed-navContent .way-wrap {
    margin-top: 15px;
    padding: 30px;
  }
  .l-main .fixed-navContent.is-open {
    bottom: auto;
    right: 0;
  }
  .l-main .searchCriteria-wrap .li-searchCriteria {
    display: flex;
    flex-wrap: wrap;
    line-height: 1.35;
  }
  .l-main .searchCriteria-wrap .li-searchCriteria dt {
    width: 184px;
    padding: 7px 5px;
    border-bottom: 1px solid #f4f4f4;
  }
  .l-main .searchCriteria-wrap .li-searchCriteria dd {
    padding: 7px 5px;
    width: calc(100% - 184px);
  }
  .l-main .basicInfo-wrap .c-columns + .c-columns {
    margin-top: 15px;
  }
  .l-main .basicInfo-wrap .c-columns.ttl-wrap {
    margin-bottom: 0;
  }
  .l-main .basicInfo-wrap .c-columns.ttl-wrap .ttl {
    display: block;
    padding-top: 5px;
    color: #2d720a;
    font-size: 1rem;
    font-style: italic;
  }
  .l-main .basicInfo-wrap .c-columns.ttl-wrap .col-basicInfo {
    display: flex;
    justify-content: space-between;
  }
  .l-main .basicInfo-wrap .c-columns.ttl-wrap .col-detailInfo {
    padding-left: 30px;
  }
  .l-main .basicInfo-wrap .c-columns:not(.ttl-wrap) .col-basicInfo {
    padding: 0 30px 0 0;
  }
  .l-main .basicInfo-wrap .c-columns:not(.ttl-wrap) .col-detailInfo {
    margin: -15px 0;
    padding: 15px 0 15px 30px;
    border-left: 1px solid #f4f4f4;
    border-top: 0;
  }
  .l-main .basicInfo-wrap .col-basicInfo {
    flex: 1;
    padding: 0 30px 0 0;
  }
  .l-main .basicInfo-wrap .col-detailInfo {
    width: 30%;
  }
  .l-main .basicInfo-wrap .txt-attention {
    display: flex;
  }
  .l-main .basicInfo-wrap .txt-attention dt {
    width: 105px;
  }
  .l-main .basicInfo-wrap .txt-attention dd {
    flex: 1;
  }
  .l-footer .l-wrapper {
    padding: 70px 10px 20px;
  }
  .l-footer a {
    color: #fff;
  }
  .l-footer .flogo {
    margin-right: auto;
  }
  .l-footer .fnav-wrap {
    width: 80%;
  }
  .l-footer .li-fnav {
    display: block;
  }
  .l-footer .li-fnav dt {
    margin-bottom: 15px;
  }
  .l-footer .li-fnav dt ~ dt {
    margin-top: 50px;
  }
  .l-footer .li-fnav dd {
    padding-left: 15px;
  }
  .l-footer .li-fnav dd a {
    display: inline-block;
    margin-bottom: 5px;
    color: rgba(255, 255, 255, 0.7);
  }
  .l-footer .li-fnav + .li-fnav {
    margin-left: 30px;
  }
  .l-footer .txt-fotoer {
    width: 72.9%;
    margin-top: 50px;
    margin-left: auto;
    padding-bottom: 45px;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    color: rgba(255, 255, 255, 0.7);
    font-size: 0.85714rem;
  }
  .l-footer .txt-copy {
    padding: 30px;
  }
  .l-footer .txt-copy br {
    display: none;
  }
}

/* ▼PC用デザインとして付け足すデザイン */
@media all and (min-width: 960px) {
  /*	 body {
	background: url(/pc.jpg) no-repeat center top/auto auto;

	.l-container {
	background: rgba(red, .5);
	color: red;
	a {
	color: blue;
}
	img {
	opacity: .5;
	box-shadow: 0 0 0 1px blue;
}
	.l-wrapper {
	box-shadow: inset 0 0 0 1px red;
}
}
}*/
  .l-outline {
    padding-top: 155px;
    background-image: url(../img/common/bg_yellow-pc.gif);
  }
  .l-container {
    font-size: 1.14286rem;
  }
  .l-container .l-wrapper {
    max-width: 960px;
    padding: 0;
  }
  .l-container .ui-slider {
    padding-bottom: 45px;
  }
  .l-container .ui-slider .slick-arrow {
    width: 56px;
    height: 56px;
  }
  .l-container .ui-slider .slick-arrow:before {
    background-size: 16px 26px;
  }
  .l-container .ui-slider .slick-prev {
    left: 30px;
  }
  .l-container .ui-slider .slick-next {
    right: 30px;
  }
  .l-container .ui-slider.skin03 .box-slide {
    width: 280px;
  }
  .l-container .ui-tab .li-tabmenu {
    justify-content: center;
    margin-bottom: 22px;
  }
  .l-container .ui-tab .li-tabmenu li {
    flex: none;
    min-width: 216px;
  }
  .l-container .ui-tab .li-tabmenu li.is-current {
    border-color: #2d720a;
  }
  .l-container .ui-tab .box-tabWrap {
    padding: 10px 30px;
    border: none;
  }
  .l-container .ui-tab .box-tabWrap .li-news {
    margin-top: -10px;
  }
  .l-container .ui-tab .box-tabWrap .li-news li a {
    padding: 17px 20px 16px 0;
  }
  .l-container .ui-tab .box-tabWrap .li-news li a:after {
    font-size: 11px;
  }
  .l-container .ui-spAccordion .ui-spacContent .li-pageMenu li + li {
    margin-left: 10px;
  }
  .l-container .ui-spAccordion .ui-spacContent .li-pageMenu li:nth-child(1) img {
    width: 23px;
  }
  .l-container .ui-spAccordion .ui-spacContent .li-pageMenu li:nth-child(2) img {
    width: 21px;
  }
  .l-container .ui-spAccordion .ui-spacContent .li-pageMenu li:nth-child(3) img {
    width: 21px;
  }
  .l-container .ui-spAccordion .ui-spacContent .li-pageMenu li:nth-child(4) img {
    width: 24px;
  }
  .l-container .ui-spAccordion .ui-spacContent .li-pageMenu li:nth-child(5) img {
    height: 22px;
  }
  .l-container .ui-spAccordion .ui-spacContent .li-pageMenu a {
    padding: 24px 10px;
  }
  .l-container .ui-spAccordion .ui-spacContent .li-pageMenu a span {
    font-size: 1rem;
  }
  .l-container .ui-acTitle {
    padding: 22px 30px 20px 30px;
    font-size: 1.85714rem;
  }
  .l-container .ui-acTitle:after {
    right: 35px;
    top: 50%;
    transform: translateY(-50%);
    width: 30px;
    height: 30px;
  }
  .l-container .ui-more .btn-plus img {
    width: 30px;
  }
  .l-container .c-title {
    font-size: 1.42857rem;
  }
  .l-container .c-title.is-pagetitle {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .l-container .c-title.is-pagetitle .icon {
    display: inline-block;
  }
  .l-container .c-title.is-pagetitle .text {
    display: inline-block;
    font-size: 2.28571rem;
  }
  .l-container .c-title.is-level1 {
    margin-bottom: 15px;
    font-size: 2.28571rem;
  }
  .l-container .c-title.is-level2 {
    font-size: 1.85714rem;
  }
  .l-container .c-title.is-level2 span {
    font-size: 1.14286rem;
  }
  .l-container .c-btn.is-round {
    border: none;
  }
  .l-container .c-link.circle-r:after {
    width: 30px;
    height: 30px;
  }
  .l-container .c-link.circle-l:before {
    width: 30px;
    height: 30px;
  }
  .l-container .c-link.circle-t:before {
    width: 30px;
    height: 30px;
    background-size: 6px auto;
  }
  .l-container .c-roundbox {
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.04);
  }
  .l-container .c-card {
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.04);
  }
  .l-container .c-card .text {
    padding: 15px 20px;
    font-size: 1.14286rem;
  }
  .l-container .c-card .text .ttl {
    margin-bottom: 5px;
    font-size: 1.14286rem;
  }
  .l-container .c-card.ar-r:after {
    right: 15px;
    bottom: 15px;
    width: 30px;
    height: 30px;
  }
  .l-container .c-card.is-horizontal {
    border-radius: 0 0 32px 0;
  }
  .l-container .c-card.is-horizontal figure {
    width: 220px;
  }
  .l-container .c-card.is-horizontal .is-imgfit {
    width: 220px;
  }
  .l-container .c-card.is-horizontal .text {
    font-size: 1.14286rem;
  }
  .l-container .c-card.is-horizontal .text .ttl {
    font-size: 1.14286rem;
  }
  .l-container .c-card.is-horizontal.ar-r:after {
    right: 15px;
    bottom: 15px;
  }
  .l-container .c-card.pc-horizontal figure {
    width: 220px;
  }
  .l-container .c-roundbox {
    padding: 30px;
  }
  .l-container .c-snsList li {
    width: calc(33.3333% - 30px);
    margin-left: 30px;
  }
  .l-container .c-snsList [class^=btn] {
    height: 80px;
  }
  .l-container .c-snsList [class^=btn] img {
    max-width: 33px;
    max-height: 33px;
  }
  .l-container .c-shareList {
    padding: 20px 0;
  }
  .l-container .is-fsizeSmaller {
    font-size: 0.85714rem;
  }
  .l-container .is-fsizeSmall {
    font-size: 1rem;
  }
  .l-container .is-fsizeNormal {
    font-size: 1.14286rem;
  }
  .l-container .is-fsizeLarge {
    font-size: 1.28571rem;
  }
  .l-container .is-fsizeLarger {
    font-size: 1.57143rem;
  }
  .l-header {
    padding: 16px;
  }
  .l-header .inner {
    border-radius: 4px;
  }
  .l-header .logo-wrap {
    padding: 12px 15px;
  }
  .l-header .logo-wrap .logo {
    width: auto;
    border: none;
  }
  .l-header .logo-wrap .logo_nexco {
    width: 60px;
    margin-left: 5px;
  }
  .l-header .logo-wrap .btn-anchor {
    display: inline-block !important;
    width: 100px;
    margin-top: 5px;
    border: none;
    font-size: 1rem;
    text-align: center;
  }
  .l-header .logo-wrap .btn-language {
    width: 164px;
  }
  .l-header .logo-wrap .btn-login {
    margin-right: 5px;
    padding: 3px 0px;
    letter-spacing: -.5px;
  }
  .l-header .l-gnav .li-pcNav a {
    display: flex;
    align-items: center;
    height: 100%;
    font-size: 1rem;
  }
  .l-header .l-gnav .li-pcNav .icon img {
    margin-right: 5px;
    max-width: 35px;
    max-height: 30px;
  }
  .l-header .c-searchWrap .c-search {
    border: none;
    width: 164px;
  }
  .l-header .c-searchWrap .icon-search {
    width: 13px;
    height: 13px;
  }
  .l-main {
    padding-bottom: 75px;
  }
  .l-main .mv-wrap {
    margin-top: 15px;
    padding-top: 16px;
    margin-bottom: 36px;
  }
  .l-main .mv-wrap .slick-slide {
    padding: 0 8px;
  }
  .l-main .search-wrap {
    padding: 20px 0 25px;
  }
  .l-main .search-wrap .c-roundbox {
    flex: none;
    padding: 15px 15px 0;
  }
  .l-main .search-wrap .c-roundbox .c-title {
    border: none;
    text-align: center;
  }
  .l-main .search-wrap .c-roundbox .c-title .icon {
    display: block;
    margin-bottom: 15px;
  }
  .l-main .search-wrap .c-roundbox .c-title .icon img {
    max-width: 38px;
    max-height: 31px;
  }
  .l-main .search-wrap .c-roundbox .label-search span {
    font-size: 1rem;
  }
  .l-main .search-wrap .c-roundbox .label-search input {
    font-size: 1.14286rem;
  }
  .l-main .search-wrap .c-roundbox .label-search.departure {
    margin-bottom: 42px;
  }
  .l-main .search-wrap .c-roundbox .label-search.departure:after {
    left: 50%;
    transform: translateX(-50%);
    width: 16px;
    height: 40px;
  }
  .l-main .search-wrap .box-trafic.c-roundbox {
    width: 352px !important;
  }
  .l-main .search-wrap .box-route, .l-main .search-wrap .box-sa {
    width: 284px !important;
  }
  .l-main .search-wrap .c-roundbox .btn-wrap {
    text-align: center;
  }
  .l-main .search-wrap .c-roundbox .c-btn {
    width: 188px;
    height: 48px;
    padding: 0;
    line-height: 48px;
  }
  .l-main .search-wrap .c-roundbox .c-btn.is-sizeS {
    width: 118px;
    height: 34px;
    margin-right: 0;
    line-height: 34px;
  }
  .l-main .search-wrap .c-roundbox .li-servicearea .label-radio {
    font-size: 1rem;
  }
  .l-main .search-wrap .c-roundbox .c-searchWrap {
    display: block;
  }
  .l-main .search-wrap .c-roundbox .c-searchWrap .c-search {
    width: 100%;
    height: 48px;
    padding: 10px 20px;
  }
  .l-main .search-wrap .c-roundbox .li-trafic {
    margin-top: -10px;
  }
  .l-main .search-wrap .c-roundbox .li-trafic li a {
    padding: 7px 0 10px;
  }
  .l-main .search-wrap .c-roundbox .li-trafic li a:after {
    content: none;
  }
  .l-main .search-wrap .c-roundbox .li-trafic li .txt-mark img {
    width: 32px;
  }
  .l-main .search-wrap .c-roundbox .li-trafic li .txt-area {
    margin-top: 5px;
    font-size: 1rem;
    text-align: center;
  }
  .l-main .search-wrap .c-roundbox .li-trafic li .txt-area:after {
    display: inline-block;
    content: "";
    right: 5px;
    bottom: 5px;
    width: 21px;
    height: 21px;
    margin-left: 5px;
    border: 1px solid #cbdfc0;
    border-radius: 50%;
    background: url(../img/common/icon_window_gr.png) no-repeat center center/9px 9px;
    vertical-align: bottom;
  }
  .l-main .search-wrap .c-roundbox .box-trafic .txt {
    font-size: 1rem;
  }
  .l-main .search-wrap .c-roundbox .box-trafic .txt-date {
    font-size: 1rem;
    font-weight: bold;
    font-style: italic;
  }
  .l-main .infomation-wrap {
    padding: 65px 0;
  }
  .l-main .infomation-wrap .c-roundbox {
    padding: 15px 35px 85px;
  }
  .l-main .infomation-wrap .c-roundbox .c-title {
    margin-bottom: 30px;
    margin-left: -35px;
    margin-right: -35px;
    padding: 10px 20px 20px;
    font-size: 1.85714rem;
  }
  .l-main .infomation-wrap .c-card .text {
    padding: 16px;
    font-size: 1.42857rem;
  }
  .l-main .news-wrap {
    padding: 62px 0 42px;
    background: #fff;
  }
  .l-main .news-wrap .c-title {
    font-size: 1.85714rem;
  }
  .l-main .news-wrap .c-roundbox {
    display: flex;
    padding: 0;
  }
  .l-main .news-wrap .c-roundbox .c-title.has-en {
    width: 218px;
    margin: 0;
    padding: 80px 0 0 35px;
    border-right: 1px solid #f4f4f4;
    font-size: 1.14286rem;
    font-style: normal;
  }
  .l-main .news-wrap .c-roundbox .c-title.has-en span {
    display: block;
    font-size: 3.42857rem;
  }
  .l-main .news-wrap .c-roundbox > div {
    padding-bottom: 25px;
  }
  .l-main .news-wrap .btn-plus {
    display: inline-block;
    padding-top: 15px;
  }
  .l-main .news-wrap .btn-plus img {
    width: 30px;
  }
  .l-main .li-news {
    margin: auto;
    padding: 40px 40px 0;
    border-top: none;
  }
  .l-main .li-news li {
    padding: 7px 0 7px 30px;
    border: none;
  }
  .l-main .li-news li:before {
    top: 18px;
  }
  .l-main .pickup-wrap {
    padding: 10px 0 45px;
    background: #fff;
  }
  .l-main .pickup-wrap .c-title {
    margin-bottom: 17px;
    font-size: 2.28571rem;
  }
  .l-main .pickup-wrap .c-title img {
    width: 13px;
  }
  .l-main .pickup-wrap .box-slide {
    width: calc((99.9999% - 114px )/ 3);
    padding: 0;
  }
  .l-main .various-wrap {
    padding: 65px 0 50px;
  }
  .l-main .various-wrap .c-roundbox {
    padding: 30px 30px 65px;
  }
  .l-main .various-wrap .c-roundbox .c-title {
    margin-bottom: 25px;
    font-size: 1.85714rem;
  }
  .l-main .app-wrap .c-roundbox {
    padding: 30px 30px 8px;
  }
  .l-main .app-wrap .c-roundbox .c-title {
    margin-bottom: 25px;
    border: none;
    font-size: 1.85714rem;
  }
  .l-main .app-wrap .c-roundbox .li-app {
    border: none;
  }
  .l-main .app-wrap .c-roundbox .li-app li .icon {
    width: 85px;
    height: 85px;
    margin-bottom: 15px;
  }
  .l-main .app-wrap .c-roundbox .li-app li .icon img {
    max-width: 30px;
    max-height: 44px;
  }
  .l-main .app-wrap .c-roundbox .li-app li .text:after {
    width: 30px;
    height: 30px;
  }
  .l-main .pagetitle-wrap {
    margin-bottom: 45px;
  }
  .l-main .pagetitle-wrap .topicpath-wrap {
    padding: 10px 30px;
  }
  .l-main .pagetitle-wrap .bg-sapa {
    padding: 50px 0;
    background-image: url(../img/sapa/bg_sapatop-pc.jpg);
  }
  .l-main .pagetitle-wrap .bg-sapa .c-title {
    margin-bottom: 50px;
  }
  .l-main .pagetitle-wrap .bg-sapa .li-servicearea {
    display: flex;
    align-items: center;
    margin-bottom: 35px;
  }
  .l-main .pagetitle-wrap .bg-sapa .li-servicearea .box-radio {
    margin-top: 0;
  }
  .l-main .pagetitle-wrap .bg-sapa .box-search .c-btn {
    height: 48px;
    padding: 11px 10px;
  }
  .l-main .pagetitle-wrap .c-roundbox .li-servicearea {
    max-width: none;
  }
  .l-main .pagetitle-wrap.detail {
    margin-bottom: 60px;
  }
  .l-main .pagetitle-wrap.detail .c-title.is-pagetitle {
    flex-direction: column;
  }
  .l-main .pagetitle-wrap.result {
    margin-bottom: 0;
  }
  .l-main .pagetitle-wrap.result .c-title {
    font-size: 2.57143rem;
  }
  .l-main .pagetitle-wrap.result .box-title .l-wrapper {
    padding-top: 40px;
    padding-bottom: 70px;
  }
  .l-main .recommned-wrap {
    padding: 45px 0 50px;
  }
  .l-main .recommned-wrap .l-wrapper {
    max-width: none;
  }
  .l-main .recommned-wrap .slick-dots {
    bottom: 0;
  }
  .l-main .sapanews-wrap {
    /*
				.li-news {
					border: none;
					li {
						margin-bottom: 22px;
						a {
							display: flex;
							padding: 0!important;
							border: none;
							&:before {
								top: 11px;
								left: 0;
							}
						}
						.txt-date {
							width: 118px;
							margin-right: 0;
							padding-left: 18px;
						}
						.ttl {
							width: calc(100% - 118px);
						}
					}
			}*/
  }
  .l-main .sapanews-wrap .c-roundbox {
    padding: 30px;
  }
  .l-main .sapanews-wrap .li-news {
    padding: 0;
  }
  .l-main .sapanews-wrap .li-news li + li {
    margin-top: 15px;
  }
  .l-main .sapanews-wrap .li-news li:before {
    top: 17px;
  }
  .l-main .sapanews-wrap .li-news li a {
    display: flex;
    padding: 0 !important;
  }
  .l-main .sapanews-wrap .li-news li .txt-date {
    width: 90px;
  }
  .l-main .sapanews-wrap .li-news li .ttl {
    flex: 1;
  }
  .l-main .sapanews-wrap .btn-plus img {
    width: 30px;
  }
  .l-main .sapanews-wrap .btn-plus img:hover {
    cursor: pointer;
  }
  .l-main .check-wrap {
    padding: 60px 0 90px;
  }
  .l-main .check-wrap .c-title {
    border-bottom: 0;
  }
  .l-main .history-wrap .c-roundbox {
    padding: 15px 30px 60px;
  }
  .l-main .history-wrap .c-title {
    margin: 0 -30px 30px;
    padding: 10px 30px 20px;
  }
  .l-main .history-wrap .li-history .ttl {
    font-size: 1.14286rem;
  }
  .l-main .history-wrap .li-history .text {
    font-size: 1.14286rem;
  }
  .l-main .fun-wrap {
    margin-top: 0;
    padding: 40px 0 90px;
  }
  .l-main .fun-wrap .c-title {
    border: 0;
    margin-bottom: 20px;
  }
  .l-main .etcinfo-wrap .c-roundbox {
    padding: 15px 0 60px;
  }
  .l-main .etcinfo-wrap .c-title {
    margin: 0 0 0;
    padding: 10px 30px 20px;
  }
  .l-main .etcinfo-wrap .li-etcinfo {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
  }
  .l-main .etcinfo-wrap .li-etcinfo li {
    width: 50%;
  }
  .l-main .etcinfo-wrap .li-etcinfo li:nth-child(odd) {
    border-right: 1px solid #f4f4f4;
  }
  .l-main .etcinfo-wrap .li-etcinfo li a {
    padding: 26px 30px;
  }
  .l-main .etcinfo-wrap .li-etcinfo li a:after {
    transform: none !important;
    bottom: 8px !important;
    right: 8px !important;
    top: auto !important;
  }
  .l-main .sns-wrap .c-roundbox {
    padding: 20px 30px 90px;
  }
  .l-main .sns-wrap .c-title {
    margin-left: -30px;
    margin-right: -30px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 20px;
    margin-bottom: 30px;
  }
  .l-main .fbnr-wrap {
    margin-top: 0;
    padding-top: 30px;
    padding-bottom: 80px;
  }
  .l-main .share-wrap {
    margin: 50px 0 -40px;
    padding: 0;
    background: 0;
  }
  .l-main .share-wrap .c-shareList {
    padding: 18px 0;
  }
  .l-main .ranking-wrap .c-title.has-rankingicon {
    margin-bottom: 30px;
    text-align: left;
  }
  .l-main .ranking-wrap .c-title.has-rankingicon .icon {
    width: 36px;
    vertical-align: middle;
  }
  .l-main .ranking-wrap .c-roundbox {
    padding: 30px;
  }
  .l-main .ranking-wrap .c-card .icon-rank {
    top: -15px;
    left: -13px;
    width: 56px;
  }
  .l-main .ranking-wrap .c-card .text {
    padding: 20px 25px;
  }
  .l-main .ranking-wrap .c-card .txt-date, .l-main .ranking-wrap .c-card .txt-category {
    font-size: 1rem;
  }
  .l-main .catch-wrap .c-roundbox {
    padding: 30px 30px 15px;
  }
  .l-main .detailbnr-wrap {
    padding: 65px 0 90px;
  }
  .l-main .detailbnr-wrap .li-bnr {
    margin: -30px 0 0 25px;
  }
  .l-main .detailbnr-wrap .li-bnr li {
    width: calc(50% - 25px);
    margin: 30px 0 0 25px;
  }
  .l-main .event-wrap .c-roundbox {
    padding: 30px;
  }
  .l-main .event-wrap .c-title {
    margin-bottom: 10px;
  }
  .l-main .areasearch-wrap .c-title,
  .l-main .highway-wrap .c-title {
    padding: 23px 30px;
  }
  .l-main .areasearch-wrap .li-areasearch li a,
  .l-main .highway-wrap .li-areasearch li a {
    padding: 30px 30px;
  }
  .l-main .areasearch-wrap {
    margin-bottom: 60px !important;
  }
  .l-main .highway-wrap .c-roundbox {
    padding: 0 0 75px 0;
  }
  .l-main .highway-wrap .box-map {
    padding: 32px 32px 24px;
  }
  .l-main .way-wrap {
    padding: 10px 30px 0px;
  }
  .l-main .way-wrap .txt-direction {
    margin-top: 3px;
    font-size: 1rem;
  }
  .l-main .way-wrap .txt-areaName {
    margin-top: 13px;
    font-size: 1.85714rem;
  }
  .l-main .way-wrap + .way-wrap {
    border-top: 0;
    padding-top: 0;
  }
  .l-main .way-wrap .c-link {
    font-size: 1.85714rem;
  }
  .l-main .way-wrap .ttl-icName span {
    font-size: 1rem;
  }
  .l-main .way-wrap .txt-line .left:before {
    width: 30px;
    height: 30px;
  }
  .l-main .way-wrap .road {
    font-size: 0.85714rem;
  }
  .l-main .fixed-navContent {
    width: 714px;
  }
  .l-main .fixed-navContent .li-search {
    padding: 55px 20px 15px 35px;
  }
  .l-main .fixed-navContent .li-search dt:nth-of-type(1) {
    width: 100%;
  }
  .l-main .fixed-navContent .li-search dt:nth-of-type(2) {
    display: flex;
    align-items: center;
    width: auto;
    padding-right: 10px;
  }
  .l-main .fixed-navContent .li-search dd:nth-of-type(1) {
    width: auto;
  }
  .l-main .fixed-navContent .li-search dd:nth-of-type(2) {
    display: flex;
    align-items: center;
    width: auto;
  }
  .l-main .fixed-navContent .li-search .c-searchWrap {
    width: 455px;
    margin-bottom: 0;
  }
  .l-main .fixed-navContent .li-search .c-searchWrap .icon-search {
    padding: 10px 20px 10px 47px;
    font-size: 1.14286rem;
    background-size: 19px auto;
    background-position: 20px center;
  }
  .l-main .page-content .news-wrap .c-title.has-en {
    font-size: 1rem;
  }
  .l-main .page-content .news-wrap .li-news li {
    padding: 5px 0 5px 30px;
  }
  .l-main .searchCriteria-wrap {
    margin-top: -32px;
  }
  .l-main .searchCriteria-wrap .criteria-wrap {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  .l-main .searchCriteria-wrap .criteria-wrap .li-searchCriteria {
    width: calc(50% - 12px);
  }
  .l-main .searchCriteria-wrap .ui-acTitle {
    padding: 22px 30px;
    font-size: 1rem;
  }
  .l-main .searchCriteria-wrap .ui-acContent {
    padding: 0 22px 22px;
    border-top: 0;
  }
  .l-main .searchCriteria-wrap .li-searchCriteria {
    border-top: 1px solid #f4f4f4;
    font-size: 1rem;
  }
  .l-main .searchCriteria-wrap .is-tac {
    margin-top: 20px;
    margin-bottom: 0;
    font-size: 1.14286rem;
  }
  .l-main .basicInfo-wrap {
    margin-top: 40px;
    padding: 32px 0 64px 0;
  }
  .l-main .basicInfo-wrap .c-columns + .c-columns {
    margin-top: 0;
  }
  .l-main .basicInfo-wrap .c-columns.ttl-wrap {
    margin: 0 -30px 0;
    padding: 8px 30px 8px;
  }
  .l-main .basicInfo-wrap .c-columns.ttl-wrap .ttl {
    padding-top: 5px;
  }
  .l-main .basicInfo-wrap .c-columns.ttl-wrap .c-btn {
    font-size: 1rem;
  }
  .l-main .basicInfo-wrap .c-columns.ttl-wrap .col-basicInfo {
    padding-right: 15px;
  }
  .l-main .basicInfo-wrap .c-columns:not(.ttl-wrap) .col-detailInfo {
    margin: 0;
    padding: 30px 0 0 30px;
  }
  .l-main .basicInfo-wrap .c-columns:not(.ttl-wrap) .col-basicInfo {
    padding: 24px 30px 5px 5px;
  }
  .l-main .basicInfo-wrap .c-columns:not(.ttl-wrap) .col-basicInfo p .c-link {
    font-size: 1.71429rem;
  }
  .l-main .basicInfo-wrap .c-columns:not(.ttl-wrap) .col-basicInfo p + p {
    margin-top: 10px;
  }
  .l-main .basicInfo-wrap .col-basicInfo .li-icon {
    flex-wrap: nowrap;
    border-radius: 8px;
    margin: 15px 0 20px;
  }
  .l-main .basicInfo-wrap .col-basicInfo .li-icon:after {
    content: none;
  }
  .l-main .basicInfo-wrap .col-basicInfo .li-icon li {
    width: calc(100% / 11);
    border: none;
    border-left: 1px solid #cbdfc0;
  }
  .l-main .basicInfo-wrap .col-basicInfo .li-icon li:first-child {
    flex: 1;
    width: auto;
    border-left: 0;
    border-radius: 8px 0 0 8px;
  }
  .l-main .basicInfo-wrap .col-basicInfo .li-icon li:nth-child(6), .l-main .basicInfo-wrap .col-basicInfo .li-icon li:nth-child(7) {
    border-radius: 0;
  }
  .l-main .basicInfo-wrap .col-basicInfo .li-icon li:last-child {
    border-radius: 0 8px 8px 0;
  }
  .l-main .basicInfo-wrap .col-detailInfo {
    width: 327px;
    font-size: 1rem;
  }
  .l-main .basicInfo-wrap .c-roundbox {
    padding: 0 30px;
  }
  .l-main .basicInfo-wrap .c-roundbox + .c-roundbox {
    margin-top: 34px;
  }
  .l-main .basicInfo-wrap .txt-attention {
    margin: 35px 0 25px;
  }
  .l-main .basicInfo-wrap .txt-attention dt:before {
    width: 24px;
    height: 24px;
    margin-right: 5px;
    border-width: 2px;
    font-size: 1.14286rem;
    line-height: 24px;
  }
  .l-main .basicInfo-wrap .txt-attention dd {
    font-size: 1rem;
  }
  .l-main .mark-wrap .c-title {
    margin: 0 0 20px;
    font-size: 1.85714rem;
  }
  .l-main .mark-wrap .li-mark {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -30px 35px;
    border-bottom: 1px solid #f4f4f4;
  }
  .l-main .mark-wrap .li-mark li {
    width: 50%;
    margin: 0 0 0;
    padding: 16px 32px 25px;
  }
  .l-main .mark-wrap .li-mark li:nth-child(2) {
    border-top: 1px solid #f4f4f4;
  }
  .l-main .mark-wrap .li-mark li:last-child {
    border-bottom: none;
  }
  .l-main .mark-wrap .li-mark li:nth-child(odd) {
    border-right: 1px solid #f4f4f4;
  }
  .l-main .mark-wrap .li-mark .ttl {
    margin-bottom: 10px;
    font-size: 1.85714rem;
  }
  .l-main .mark-wrap .li-mark .ttl .icon {
    width: 54px;
    margin-right: 12px;
    border-radius: 8px;
  }
  .l-footer .btn-top {
    margin-bottom: 70px;
    background: #2d720a;
  }
  .l-footer .btn-top a {
    padding: 10px;
    font-size: 1.28571rem;
  }
  .l-footer .li-fnav dd a {
    font-size: 1rem;
  }
  .is-ie11 .l-main .sapanews-wrap .li-news li a {
    width: 100%;
  }
}

@media all and (min-width: 1055px) {
  .l-header .l-gnav {
    padding: 0 35px 0;
  }
  .l-header .l-gnav .li-pcNav .icon img {
    margin-right: 10px;
    max-width: 38px;
    max-height: 37px;
  }
  .l-header .logo-wrap {
    padding: 12px 30px;
  }
  .l-header .logo-wrap .logo {
    padding-right: 10px;
  }
  .l-header .logo-wrap .logo_nexco {
    width: 73px;
    margin-left: 30px;
  }
  .l-header .logo-wrap .btn-login {
    margin-right: 25px;
  }
}

@media all and (min-width: 1120px) {
  .l-header {
    max-width: 1248px;
    margin-left: auto;
    margin-right: auto;
  }
  .l-header .logo-wrap .btn-language {
    margin-right: 13px;
  }
  .l-header .logo-wrap .btn-login {
    padding: 4px 10px 3px;
    letter-spacing: 0;
  }
}

@media all and (min-width: 1248px) {
  .is-ie11 .l-header {
    width: 1248px;
  }
}

/* #Print Fixes */
@media print {
  * {
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }
  @page {
    margin: 1cm 2cm;
  }
  p, h2, h3 {
    orphans: 3;
    widows: 3;
  }
  h2, h3 {
    page-break-after: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr, img {
    page-break-inside: avoid;
  }
  body {
    width: auto;
    -webkit-print-color-adjust: exact;
  }
}
